import React from 'react'
import { useClasses, useQueryParam } from '../lib/ReactUtils'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import MigrationForm from '../components/MigrationForm'
import '../styles/Hero'
import BackgroundImage from 'gatsby-background-image'
import { useStaticQuery, graphql } from "gatsby"

import MIGRATE from '../images/ms365/migrate.svg'

const CLIENTID = '44f6c808-0614-4aa1-b4d4-165b8915849f'

export default function MigrationPage() {
  const root = useClasses()
  const { heroImage } = useStaticQuery(
    graphql`
      query {
        heroImage: file(
          absolutePath: { glob: "**/images/ms365/hero.jpg" }
          sourceInstanceName: { eq: "images" }   
        ) {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 50) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  const [tenant, setTenant] = useQueryParam('tenant',null)
  return (
    <Layout full dark menu={false} short>
      <SEO title='MS365 Migration #10' image={heroImage.childImageSharp.fluid.src}/>
      <main className='main' ref={root}>
        <BackgroundImage className='hero-container-app' fluid={heroImage.childImageSharp.fluid}>
          <div className='hero-overlay'/>
          <div className={'z-2 flex justify-center items-center max-h-3/4 w-full max-w-screen-xl h-130 sm:h-150 xl:h-180 mx-10 md:mx-20 rounded-lg shadow-lg ' + (tenant ? 'bg-white' : 'bg-white/70')}>
            <div className='w-full px-10 md:px-20 tracking-tight'>
              { tenant ?
                <>
                  <h2 className='text-lg sm:text-2xl md:text-3xl lg:text-4xl font-black text-center text-orange-500 mb-10'>Your freedom is almost here, you can taste!</h2> 
                  <MigrationForm tenant={tenant} platform='ms365'/>
                </>:
                <a href={"https://login.microsoftonline.com/common/adminconsent?client_id=" + CLIENTID} className="group basic">
                  <h1 className='text-3xl md:text-4xl lg:text-5xl font-black text-center text-primary-dark group-hocus:text-orange-500'>One-Click Migration #10</h1>
                  <img src={MIGRATE} className='w-3/4 lg:w-1/2 mt-10 md:mt-20 mx-auto'/>
                </a>
              }
            </div>
          </div>
        </BackgroundImage>
      </main>
    </Layout>
  )
}
